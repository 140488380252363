import * as React from "react"
import { Link } from "gatsby"

import Hamburger from "./hamburger"
import Category from "./category"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  const sales = "ランキング記事はこちら"

  let header
  if (isRootPath) {
    header = (
      <div className="border-b bg-white px-2 fixed top-0 left-0 w-full h-16 z-50 lg:static">
        <header className="flex items-center justify-between max-w-[1100px] mx-auto relative h-full">
          <span className="absolute top-2 text-xs text-gray-700">
            〇〇〇〇〇〇〇〇
          </span>
          <h1 className="">
            <Link className="block font-bold text-lg lg:text-xl -mb-4" to="/">
              {title}
            </Link>
          </h1>
          <Hamburger>
            <Category />
          </Hamburger>
        </header>
      </div>
    )
  } else {
    header = (
      <>
        <div className="bg-white px-2 fixed top-0 left-0 w-full h-16 z-50 lg:static">
          <header className="flex items-center justify-between max-w-[1100px] mx-auto relative h-full">
            <span className="absolute top-2 text-xs text-gray-700">
              〇〇〇〇〇〇〇〇
            </span>
            <Link className="block font-bold text-lg lg:text-xl -mb-4" to="/">
              {title}
            </Link>
            <Hamburger>
              <Category />
            </Hamburger>
          </header>
        </div>
        <div className="text-center bg-blue-300 sticky top-16 left-0 z-10 backdrop-filter backdrop-blur bg-opacity-50 lg:top-0">
          <Link
            to="/"
            className="font-bold text-sm block py-2 hover:opacity-40 duration-300"
          >
            {sales}
          </Link>
        </div>
      </>
    )
  }

  return (
    <div className="bg-blue-50" data-is-root-path={isRootPath}>
      {header}
      <div className="max-w-[1100px] mx-auto mt-16 lg:my-[40px]">
        {children}
      </div>
      <footer className="text-center bg-white text-sm">
        <div className="py-4">
          © {new Date().getFullYear()}
          {` `}
          {title}
        </div>
      </footer>
    </div>
  )
}

export default Layout
