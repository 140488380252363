import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import { BiFolderOpen } from "react-icons/bi"

const Category = () => {
  const data = useStaticQuery(
    graphql`
      query {
        categoryGroup: allMarkdownRemark(limit: 2000) {
          group(field: frontmatter___category) {
            fieldValue
            totalCount
          }
        }
      }
    `
  )
  return (
    <div className="bg-white rounded p-4 md:p-8 border-t lg:border-none">
      <p className="mb-4 md:mb-8">
        <BiFolderOpen className="h-4 w-4 inline-block text-blue-400 mr-1" />
        <span className="text-sm font-bold">カテゴリー</span>
      </p>
      <nav className="">
        <ul className="">
          {data.categoryGroup.group.map(cat => (
            <li
              className="hover:bg-blue-50 border-b border-blue-50 py-2 pr-2"
              key={cat.fieldValue}
            >
              <Link
                to={`/category/${kebabCase(cat.fieldValue)}/`}
                className="flex items-center justify-between font-bold"
              >
                <p className="">
                  <BiFolderOpen className="h-4 w-4 inline-block text-blue-400 mr-1" />
                  <span className="inline-block text-sm">{cat.fieldValue}</span>
                </p>
                <span className="text-white bg-blue-400 rounded-full w-8 h-8 leading-8 text-sm text-center">
                  {cat.totalCount}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default Category
