import React from "react"
import { Link } from "gatsby"
import { BiCaretRight } from "react-icons/bi"

const Links = props => {
  return (
    <Link
      to={props.link}
      className="block text-center py-4 bg-gray-50 lg:hover:bg-gray-100 duration-300 relative"
    >
      {props.title}
      <BiCaretRight className="inline-block w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-2" />
    </Link>
  )
}

export default Links
