import React from "react"
import { BiMenu, BiX } from "react-icons/bi"
import Links from "./links"

const Hamburger = props => {
  const Button = props => {
    return (
      <>
        <input className="hidden" id="nav-checkbox" type="checkbox" />
        <label className="" htmlFor="nav-checkbox">
          <p className="p-2 cursor-pointer text-center duration-100 hover:bg-blue-50 border-blue-50 hover:border-blue-200">
            {props.children}
            <span className="text-[10px] block">{props.title}</span>
          </p>
        </label>
      </>
    )
  }
  const ActiveBackground = () => {
    return (
      <label
        className="hidden z-40 fixed top-0 right-0 w-full h-full bg-black opacity-0 duration-300 nav-bg"
        htmlFor="nav-checkbox"
      >
        <span></span>
      </label>
    )
  }
  return (
    <>
      <Button title="Menu">
        <BiMenu className="inline-block w-6 h-6" />
      </Button>
      {/* 🍔 */}
      <ActiveBackground />
      <div className="fixed overflow-auto top-0 right-0 z-50 h-full bg-white shadow-2xl duration-300 text-left nav-content">
        <div className="flex items-center justify-end bg-white">
          <Button title="Close">
            <BiX className="inline-block w-6 h-6" />
          </Button>
        </div>
        {props.children}
        <Links link="/page/" title="記事一覧" />
      </div>
    </>
  )
}

export default Hamburger
